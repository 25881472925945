import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface Rider {
  ridersID: string;
  ridersFullName: string;
}

export interface ListResponse {
    data: Rider[];
}
interface searchProps {
  TPLID: string | null;
}

export const riderApi = createApi({
  reducerPath: "riderApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL,
    credentials: "same-origin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("barcode_token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    listRiders: builder.query<ListResponse, void>({ 
      query: () => "riders", 
    }),
    list3PLRiders: builder.query<ListResponse, searchProps>({ 
      query: ({ TPLID }) => `/riders/tpl/${TPLID}`, 
    }),
  }),
});

export const { useListRidersQuery, useList3PLRidersQuery } = riderApi;
