import React, { useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/Login";
import Order from "./pages/Order";
import SearchPage from "./pages/SearchPage";
import PrintPage from "./pages/PrintPage";
import { NotFound } from "./pages/NotFound";
import Scanner from "./pages/Scanner";

function App() {
  return (
    <BrowserRouter>
      <MyComponent />
    </BrowserRouter>
  );
}

function MyComponent() {
  const [searchData, setSearchData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const todayDate = new Date().toJSON().slice(0, 10);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [searchText, setSearchText] = useState("");
  const [value, setValue] = useState("");
  const [riderValue, setRiderValue] = useState("");
  const [status, setStatus] = useState("");
  const [dateType, setDateType] = useState("");
  const [searchType, setSearchType] = useState("");



  const userType = localStorage.getItem("usertype");
  const navigate = useNavigate();

  const onLogOut = () => {
    localStorage.removeItem("barcode_token");
    localStorage.removeItem("userId");
    localStorage.removeItem("usertype");
    navigate("/login");
  };

  const onClick = () => {
    setSearchText("");
    setSearchType("");
    setEndDate(undefined)
    setStartDate(undefined)
    setDateType("");
    setValue("");
    setRiderValue("")
    setStatus("");
    navigate("/");
  };
 
  const onClickBackToOrder = () => {
    navigate({
      pathname: "/order",
    });
  };

  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route
          path="/login"
          element={<Login />}
        />
      </Route>
      <Route element={<ProtectRoute />}>
        {userType?.toLowerCase() === "fez_admin" ? (
          <Route path={"/scan-qr-code"} element={<Scanner />} />
        ) : (
          <Route path={"/"} element={<Scanner />} />
        )}

        {userType?.toLowerCase() === "fez_admin" && (
          <>
            <Route
              path="/"
              element={
                <SearchPage
                  startDate={startDate}
                  endDate={endDate}
                  value={value}
                  setValue={setValue}
                  riderValue={riderValue}
                  setRiderValue={setRiderValue}
                  searchText={searchText}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setSearchText={setSearchText}
                  setSearchData={setSearchData}
                  currentPage={currentPage}
                  onLogOut={onLogOut}
                  dateType={dateType}
                  setDateType={setDateType}
                  status={status}
                  setStatus={setStatus}
                  setSearchType={setSearchType}
                  searchType={searchType}
                />
              }
            />
            <Route
              path="/order"
              element={
                <Order
                  setSearchData={setSearchData}
                  searchData={searchData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  onClickBack={onClick}
                  onLogOut={onLogOut}
                />
              }
            />
            <Route
              path="/print"
              element={
                <PrintPage
                  setSearchData={setSearchData}
                  searchData={searchData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  onClickBack={onClickBackToOrder}
                  onLogOut={onLogOut}
                />
              }
            />
          </>
        )}

        <Route path={"*"} element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export const PublicRoute = () => {
  const user: any = localStorage.getItem("barcode_token") !== null;
  return !user ? <Outlet /> : <Navigate to="/" />;
};

export const ProtectRoute = () => {
  const user: any = localStorage.getItem("barcode_token") !== null;
  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default App;
