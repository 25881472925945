import type { GroupBase, OptionsOrGroups } from "react-select";

export type OptionType = {
  value: string;
  label: string;
};

const options: OptionType[] = [
  {
    value: "Assigned To A Rider",
    label: "Assigned To A Rider",
  },
  {
    value: "Picked-Up",
    label: "Picked-Up",
  },
  {
    value: "Exported",
    label: "Exported",
  },
  {
    value: "Accepted At Inventory Facility",
    label: "Accepted At Inventory Facility",
  },
  {
    value: "Rejected At Inventory Facility",
    label: "Rejected At Inventory Facility",
  },
  {
    value: "Dispatched",
    label: "Dispatched",
  },
  {
    value: "Enroute To Last Mile Hub",
    label: "Enroute To Last Mile Hub",
  },
  {
    value: "Accepted At Last Mile Hub",
    label: "Accepted At Last Mile Hub",
  },
  {
    value: "Rejected At Last Mile Hub",
    label: "Rejected At Last Mile Hub",
  },
  {
    value: "Delivered",
    label: "Delivered",
  },

  {
    value: "In Return To Last Mile Hub",
    label: "In Return To Last Mile Hub",
  },
  {
    value: "Returned To Last Mile Hub",
    label: "Returned To Last Mile Hub",
  },
  {
    value: "In Return To First Mile Hub",
    label: "In Return To First Mile Hub",
  },
  {
    value: "Returned To First Mile Hub",
    label: "Returned To First Mile Hub",
  },
  {
    value: "In Return To Customer",
    label: "In Return To Customer",
  },
  {
    value: "Returned",
    label: "Returned",
  },
];
const dateOptions: OptionType[] = [
  {
    label: "Created Date",
    value: "createdDate",
  },
  {
    label: "Pick-up Date",
    value: "pickUpDate",
  },
  {
    label: "Dispatch Date",
    value: "dispatchDate",
  },

  {
    label: "Assigned To Rider Date",
    value: "assignedToRiderDate",
  },
];

const searchOptions: OptionType[] = [
  {
    label: "Order Number",
    value: "order_number",
  },
  {
    label: "Item Description",
    value: "item_description",
  },
  {
    label: "Delivery Rider",
    value: "delivery_rider",
  },

  {
    label: "Received by Name",
    value: "received_by_name",
  },
  {
    label: "Received by Phone",
    value: "received_by_phone",
  },
  {
    label: "Recipient Details",
    value: "recipient_details",
  },
  {
    label: "Waybill Number",
    value: "waybill_number",
  },
  {
    label: "Client Details",
    value: "client_details",
  },
  {
    label: "Sender's phone number",
    value: "thirdparty_sendersPhone",
  },
  {
    label: "Sender's Name",
    value: "thirdparty_sendersName",
  },
];

const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });

export const loadOptions = async (
  search: string,
  prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>
) => {
  await sleep(1000);

  let filteredOptions: OptionType[];
  if (!search) {
    filteredOptions = options;
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = options.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
  }

  const hasMore = filteredOptions.length > prevOptions.length + 10;
  const slicedOptions = filteredOptions.slice(
    prevOptions.length,
    prevOptions.length + 10
  );

  return {
    options: slicedOptions,
    hasMore,
  };
};

export const dateLoadOptions = async (
  search: string,
  prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>
) => {
  await sleep(1000);

  let filteredOptions: OptionType[];
  if (!search) {
    filteredOptions = dateOptions;
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = dateOptions.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
  }

  const hasMore = filteredOptions.length > prevOptions.length + 10;
  const slicedOptions = filteredOptions.slice(
    prevOptions.length,
    prevOptions.length + 10
  );

  return {
    options: slicedOptions,
    hasMore,
  };
};

export const searchLoadOptions = async (
  search: string,
  prevOptions: OptionsOrGroups<OptionType, GroupBase<OptionType>>
) => {
  await sleep(1000);

  let filteredOptions: OptionType[];
  if (!search) {
    filteredOptions = searchOptions;
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = searchOptions.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
  }

  const hasMore = filteredOptions.length > prevOptions.length + 10;
  const slicedOptions = filteredOptions.slice(
    prevOptions.length,
    prevOptions.length + 10
  );

  return {
    options: slicedOptions,
    hasMore,
  };
};