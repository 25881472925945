import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export interface TPL {
  ridersID: string;
  ridersFullName: string;
}

export interface ListResponse {
  data: TPL[];
}

export const tplApi = createApi({
  reducerPath: "tplApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL,
    credentials: "same-origin",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("barcode_token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    listTpl: builder.query<ListResponse, void>({
      query: () => "tpls",
    }),
  }),
});

export const { useListTplQuery } = tplApi;
